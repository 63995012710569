import React, { useState } from 'react';
import './App.css';
import axios from 'axios';
import swal from 'sweetalert2';
import 'animate.css';

function App() {
  const [campos, setcampos] = useState({
    campos: '',
    setcampos: ''
  });

  function handleInputChange(event) {
    campos[event.target.name] = event.target.files[0];
    setcampos(campos);

    var fileElement = document.getElementById("anexo");
    var fileExtension = "";

    if(campos.anexo !== undefined && fileElement.value.lastIndexOf(".") > 0){
      fileExtension = fileElement.value.substring(fileElement.value.lastIndexOf(".") + 1, fileElement.value.length);
    }if(fileExtension.toLowerCase() == "xlsx" || fileExtension.toLowerCase() == "xls"){
      document.getElementById("result").innerHTML = campos[event.target.name].name;
    }else {
      document.getElementById("result").innerHTML = "";
      alert("Você deve selecionar um arquivo Excel");
    }
};

function handleInputChange2(event) {
  campos[event.target.name] = event.target.files[0];
  setcampos(campos);

  var fileElement = document.getElementById("anexo2");
  var fileExtension = "";


  if(campos.anexo2 !== undefined && fileElement.value.lastIndexOf(".") > 0){
    fileExtension = fileElement.value.substring(fileElement.value.lastIndexOf(".") + 1, fileElement.value.length);
  }if(fileExtension.toLowerCase() == "pdf"){
    document.getElementById("result2").innerHTML = campos[event.target.name].name;
  }else {
    document.getElementById("result2").innerHTML = "";
    alert("Você deve selecionar um arquivo PDF");
  }
};

  function handleFormSubmit(event) {
    event.preventDefault();

    if (document.getElementById("anexo").files.length !== 0) {
      swal.fire({
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
        title: 'Deseja enviar o arquivo?',
        showCancelButton: false,
        icon: 'info',
        confirmButtonText: 'Enviar',
        showLoaderOnConfirm: true,
        //Enviando o arquivo
        preConfirm: () => {
          var formData = new FormData();
          formData.append("anexo", campos.anexo);
          if(document.getElementById("anexo2").files.length !== 0){
            formData.append("anexo2", campos.anexo2);
          }
          console.log(formData.append);
          return axios.post('https://lablaborclinica.com.br:4443/uploadGOS', formData, {
          // return axios.post('https://localhost:3333/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(response => {
            var fileerror = response.data.errorfile
            console.log(fileerror);
            if (fileerror.length === 0) {
              swal.fire({
            title: 'Arquivo enviado',
                text: 'Email enviado',
                icon: "success",
              }).then(() => {
                  window.location.reload();
                })
        
            } else {
              swal.fire({
                title: 'Arquivo inválido',
                text: JSON.stringify(fileerror),
                icon: "warning",
              }).then(() => {
                  window.location.reload();
                })
            }
          }).catch(error =>
            swal.fire({
              title: 'Arquivo não enviado',
              text: error,
              icon: "error",
              showConfirmButton: true,
            
            })
          )
        },
        allowOutsideClick: () => !swal.isLoading()
      })
    }else {
        swal.fire('Insira um arquivo.', '', 'error');
        setTimeout(() => {window.location.reload()}, 5000);
      }
  }

  return (
    <div className="container"> 
    {/* <a download="modelo.xlsx" href="modelo.xlsx" style="background-color: #7066E0;">Baixar</a> */}
      <form onSubmit={handleFormSubmit}>
        <p>Olá, insira abaixo o arquivo em excel com as informações que deseja ser enviado.</p><br></br>
        <label className="upload" htmlFor="anexo">Insira aqui o arquivo em Excel
          <input type="file" id="anexo" name="anexo" accept=".xlsx" onChange={handleInputChange} />
        </label>
        <div>
          <br></br>
          <span id="result"></span>
        </div>
        <br></br>
        <input type="submit" value="GERAR ORDENS DE SERVIÇOS" />
        <a href="https://os.laboratoriolaborclinica.com.br/" target="blank">GERAR ORDEM DE SERVIÇO AVULSA</a><br></br><br></br>
        <label className="upload2" htmlFor="anexo2">Insira pedido médico.
          <input type="file" id="anexo2" accept=".pdf" name="anexo2" onChange={handleInputChange2} />
        </label>
        <div>
          <br></br>
          <span id="result2"></span>
        </div>
      </form>
    </div>
  );
}

export default App;
